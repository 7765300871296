<template>
   <div></div>
</template>
<script>
export default {
    props: {
    file: String
  },
    data(){
       return{
       
    }
    },

  mounted () {
    window.open(this.file)
  }


}
</script>