import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import 'bootstrap'
import VueRouter from 'vue-router'
import VueSocialSharing from 'vue-social-sharing'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import "bootstrap/dist/css/bootstrap.min.css"
import CreateVideo from './components/pages/CreateVideo/CreateVideo.vue'
import Video from './components/pages/Video/Video.vue'
import Home from './components/pages/Home/Home.vue'
import UserProfile from './components/pages/Profile/UserProfile.vue'
import InfluencerProfile from './components/pages/Profile/InfluencerProfile.vue'
import Message from './components/pages/Message/Message.vue'
import Login from './components/pages/Login/Login.vue'
import FeaturedBrand from './components/pages/FeaturedBrand/FeaturedBrand.vue'
import Hashtag from './components/pages/Hashtag/Hashtag.vue'
import PostByCategory from './components/pages/Category/PostByCategory.vue'
import AllCategoryList from './components/pages/Category/AllCategoryList.vue'
import AllTrendingTopicsList from './components/pages/TrendingTopics/AllTrendingTopicsList.vue'
import AllFeaturedBrandList from './components/pages/FeaturedBrand/AllFeaturedBrandList.vue'
import AllOffersList from './components/pages/Offer/AllOffersList.vue'
import Welcome from './components/pages/LandingPages/Welcome.vue'
import Privacy from './components/pages/LandingPages/Privacy.vue'
import About from './components/pages/LandingPages/About.vue'
import Support from './components/pages/LandingPages/Support.vue'
import Link from './components/pages/LandingPages/Link.vue'
import Squad from './components/pages/LandingPages/Squad.vue'
import CoinsTnC from './components/pages/LandingPages/CoinsTnC.vue'
import TnC from './components/pages/LandingPages/TnC.vue'
import RnCpolicy from './components/pages/LandingPages/RnCpolicy.vue'
import Playback from './components/pages/LandingPages/Playback.vue'
import CampusAmbassadorLanding from './components/pages/LandingPages/CampusAmbassador/CampusAmbassadorLanding.vue'
import CampusAmbassadorRegister from './components/pages/LandingPages/CampusAmbassador/CampusAmbassadorRegister.vue'
import BringinClubTnC from './components/pages/LandingPages/BringinClubTnC.vue'
import BringinBazaar from './components/pages/LandingPages/BringinBazaar/BringinBazaar.vue'
import AppApple from './components/pages/App-apple/AppApple.vue'


library.add(fas,fab,far)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueMeta)
Vue.use(VueRouter)

Vue.use(VueSocialSharing);

const routes = [
  {path: '/', component:BringinBazaar }, 
  {path: '/link.html', component: Link },
  {path:'/coinsTnC',component:CoinsTnC,}, 
  {path: '/tnc', component: TnC }, 
  {path: '/RnCpolicy', component: RnCpolicy }, 
  {path: '/playback', component: Playback }, 
  {path: '/privacy/', component: Privacy }, 
  {path: '/bringinsquadgame.html', component: Squad }, 
  {path: '/aboutus/', component: About }, 
  {path: '/support/', component: Support }, 
  {path: '/home', component: Home }, 
  {path: '/createvideo', component:CreateVideo},
  {path:'/userprofile', component:UserProfile},
  {path:'/message', component:Message},
  {path:'/login', component:Login},
  {path:'/profile/:handle',component:InfluencerProfile},
  {path:'/watch/:post_id',component:Video,},
  {path:'/brand/:id',component:FeaturedBrand,},
  {path:'/hashtag/:hashtag',component:Hashtag,},
  {path:'/category/:category_id',component:PostByCategory,},
  {path:'/categories',component:AllCategoryList,},
  {path:'/trendingtopics',component:AllTrendingTopicsList,},
  {path:'/featuredbrands',component:AllFeaturedBrandList,},
  {path:'/Offers',component:AllOffersList,},
  {path:'/bringinClubTnC',component:BringinClubTnC,},
  {path:'/campus-ambassador',component:CampusAmbassadorLanding,},
  {path:'/campus-ambassador-register',component:CampusAmbassadorRegister,},
  {path:'/bringinBazaar', component:BringinBazaar},
  // {path:'/apple-app-site-association', component:AppApple, props: {
  //   file: './components/pages/App-apple/apple-app-site-association'
  // }}





]
const router = new VueRouter({
  routes,
  mode: 'history'
})

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
