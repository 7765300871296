<template>
    <div class="landing_page"  > 
      <header id="site-header">
         <div class="site-container d-flex align-items-center">
            <div class="logo">
              <a href="/">
                <img src="/bringin_landing/assets/images/bringin_logo_new.png" alt="" />
              </a>
            </div>
            <div style="position: absolute; right: -1px; top: -5px;">
               <div class="logo-icon mb-3" >
                    <img src="/bringin_landing/assets/images/image-12.png" alt="" style="height: 72px; transform: rotate(1deg);">
                </div>
            </div> 
        </div>
      </header>
      <main id="main-content">
        <section class="content-section" id="site-header">
          <div class="container">
            <h1 style="color: #0F9D58; font-family: 'Times New Roman', Times, serif; font-weight: bold; font-size:30px;" class="d-flex justify-content-center text-center">Customer Relationship Management</h1>
            <h1 style="color: white; font-weight: bold; font-size: 20px;" class="d-flex justify-content-center text-center mt-4">Made Easy for Boutique Owners
            </h1>
            <div class="d-flex justify-content-center">
                <img src="/bringin_landing/assets/images/1_small.png" width="100%" class="img-fluid" /> 
            </div>  
          </div>
          <div class="container">
            <h1 style="color: #0F9D58; font-family: 'Times New Roman', Times, serif; font-weight: bold;" class="d-flex justify-content-center align-items-center">About the app  </h1>
            <hr style="background-color: white; height: 4px; width: 150px; opacity: 100; margin:auto" class="mt-3 mb-3">
            <div style="color: white; width: 85%;margin: auto; text-align: center; font-size: 18px;">Your favourite labels under one fashion roof!
            </div>
            <div class="d-flex justify-content-center align-items-center my-4">
                <a  href="" style="margin: auto; font-weight: bold;" class="button button-white shadow-sm " data-bs-toggle="modal" data-bs-target="#exampleModal"><span class="d-flex align-items-center justify-content-center text-white">Enquire Now </span></a>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content container-fluid mt-4 overflow-x-hidden" style="background-color: #202529;">
                  <div class="modal-header container d-flex justify-content-center text-center">
                    <h1 class="modal-title mt-3" style="color: #0F9D58; font-family: 'Times New Roman', Times, serif; font-weight: bold; font-size:30px;" id="exampleModalLabel">Enter Details</h1>
                  </div>
                  <div class="modal-body p-1">
                    <form class="w-100">
                         <div class="mt-4 form-group">
                            <div class="top_box">
                                <span class="title">Name</span>
                            </div>
                            <div class="box d-flex justify-content-center align-items-center"><input v-model="details.name"
                            placeholder="Enter your name" color="white" 
                            class="form-control form-control-lg" required>
                            </div>
                        </div> 
                        <div class="mt-4 form-group">
                            <div class="top_box">
                                <span class="title">Store</span>
                            </div>
                            <div class="box d-flex justify-content-center align-items-center"><input v-model="details.store"
                            placeholder="Enter your store name" color="white" class="form-control form-control-lg" required>
                            </div>
                        </div> 
                        <div class="h-25 mt-4">
                            <div class="top_box">
                                <span class="title">Email</span>
                            </div>
                            <div class="box d-flex justify-content-center align-items-center"><input v-model="details.email"
                                    placeholder="Enter you email" type="email" color="white"
                                    class="form-control form-control-lg w-100" required>
                            </div>
                        </div> 
                        <div class="h-25 mt-4">
                            <div class="top_box">
                                <span class="title">Contact</span>
                            </div>
                            <div class="box d-flex justify-content-center align-items-center"><input v-model="details.mobile_no" type="text" placeholder="Enter your contact"
                            class="form-control form-control-lg w-100" required>
                            </div>
                        </div> 
                    </form>
                  </div>
                  <button class="button button-white shadow-sm my-3 mx-2" @click="submitForm" 
                   ><a style="font-weight: bold;">Submit</a>
                  </button>
                </div>
              </div>
            </div>
            <div class="modal fade modal-fullscreen"   style="background-color: #202529;" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content container-fluid mt-4 overflow-x-hidden" style="background-color: #202529;">
                  <div class="modal-body p-1">
                    <img src="/bringin_landing/assets/images/thankyou.png">
                    <button 
                    style="background-color: #202529; color: #0F9D58; margin: auto;" type="button" class=" d-flex justify-content-center my-3 fs-5 btn btn-secondary border-0" data-bs-dismiss="modal" @click="closeForm">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      <!-- <section class="content-section lightbg">
        <div class="site-container pr-0">
          <div class="category-slider">
            <div class="slide-item">
              <div class="category-block">
                <div class="figure">
                  <img style="z-index: 990;"  src="/bringin_landing/assets/images/slider2.svg" alt="" width="100%" />
                </div>
                <a href="#" class="fulllink"></a>
              </div>
            </div>
            <div class="slide-item">
              <div class="category-block">
                <div class="figure">
                  <img style="z-index: 990;" src="/bringin_landing/assets/images/slider3.svg" alt="" width="100%" />
                </div>
                <a href="#" class="fulllink"></a>
              </div>
            </div>
            <div class="slide-item">
              <div class="category-block">
                <div class="figure">
                  <img style="z-index: 990;" src="/bringin_landing/assets/images/slider1.svg" alt="" width="100%" />
                </div>
                <a href="#" class="fulllink"></a>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <section class="content-section" id="site-header" style="background-color: black;" >
        <h1 style="color: #0F9D58; font-family: 'Times New Roman', Times, serif; font-weight: bold; font-size:20px;" class="d-flex justify-content-center text-center my-5">Follow Us On Social Media  </h1>
        <div class="site-container pr-0 pb-5" >
          <div class="container d-flex">
            <div class="container">
              <div class="category-block" >
                <div class="figure">
                  <img src="/bringin_landing/assets/images/bringin-bazaar-slider4.png" alt=""/>
                </div>
                <div class="figcaption">
                  <div class="heading heading-sm txt-clr-white" style="font-size: 40px;">
                    <i class="fa-brands fa-instagram" style="color: #ffffff;"></i>
                  </div>
                </div>
                <a href="https://www.instagram.com/bringinbazaar/" target="_blank" class="fulllink"></a>
              </div>
            </div>
            <div class="container">
              <div class="category-block" >
                <div class="figure">
                  <img src="/bringin_landing/assets/images/bringin-bazaar-slider5.png" alt=""/>
                </div>
                <div class="figcaption">
                  <div class="heading heading-sm txt-clr-white"  style="font-size: 40px;">
                    <i class="fa-brands fa-facebook" style="color: #ffffff;"></i>
                  </div>
                </div>
                <a href="https://www.facebook.com/thebringinapp/"  target="_blank"  class="fulllink"></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    <!-- <div class="d-flex justify-content-center align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="position-relative d-flex justify-content-center align-items-center" >
              <img src="bringin_landing/assets/images/bringin-bazaar-slider4.png" alt="Image" style="width: 210px ;
                  height: 160px;" class="img-fluid">
              <i class="fa-brands fa-instagram position-absolute " style="font-size: 40px; color: white; "></i>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="position-relative d-flex justify-content-center align-items-center" >
              <img src="bringin_landing/assets/images/bringin-bazaar-slider5.png" alt="Image" style="width: 210px;
                    height: 160px;" class="img-fluid">
              <i class="fa-brands fa-facebook position-absolute " style="font-size: 40px; color: white; "></i>
            </div>
          </div>
        </div>
      </div>
    </div> -->
      </main>
      <footer id="site-footer">
        <div class="site-container">
          <!-- <div class="languages-wrap">
            <img src="/bringin_landing/assets/images/languages.png" alt="">
          </div> -->
          <div class="logo-icon mb-3">
            <img src="/bringin_landing/assets/images/image-12.png" alt="">
          </div>
          <div class="flinks mb-1">
            <ul>
              <li>
                <a href="/aboutus/" class="text-white">About Us</a>
              </li>
              <!-- <li>
                <a href="/blog/" class="text-white">Blogs</a>
              </li> -->
              <!-- <li>
                <a href="#">Careers</a>
              </li> -->
              <li>
                <a href="/privacy/" class="text-white">Privacy Policy</a>
              </li>
              <!-- <li>
                <a href="#">Terms of Service</a>
              </li> -->
              <li>
                <a href="/support/" class="text-white">Support</a>
              </li>
            </ul>
          </div>
          <div class="copyright">
            <p class="m-0">&copy; Copyright 2024 Bringin Web Private Limited. All rights reserved.</p>
          </div>
        </div>
        <div class="bg-vector">
          <img src="/bringin_landing/assets/images/bg-vector-light.png" alt="">
        </div>
      </footer>
    </div>
  </template>
  <script>
  import axios from 'axios'
  export default {
      name:'BringinBazaar',
      data(){
          return {
            desc: '',
            details:{
                name:'',
                store:'',
                email:'',
                mobile_no:'',

            }
          }
      },
      mounted(){

      },
      methods:{
        closeForm() {
              $(document).ready(function submitForm(){
                $("#exampleModal").modal('hide');
              });
        },
        submitForm() {
            let self = this
            let url  = "/api/v1/boutique/lead/generation/"
            let payload={
                "name": this.details.name,
                "store":this.details.store,
                "email": this.details.email,
                "mobile_no": this.details.mobile_no,
            }
            if (this.details.name && this.details.email && this.details.mobile_no && this.details.store) {
                if(!this.validateEmail(this.details.email)) {
                    alert("Invalid email address")
                    return false
                }
                if (!this.validatePhone(this.details.mobile_no)) {
                    alert("Invalid phone number")
                    return false
                }
                console.log(this.details)
                axios.post(url,payload).then((res)=> {
                    self.details.name = "",
                    self.details.store ="",
                    self.details.email = "",
                    self.details.mobile_no = ""
                    $(document).ready(function submitForm(){
                      $("#exampleModal2").modal('show');
                    });
                }).catch(err => alert(err))
            }
            else {
              
                alert("Please fill all the required fields")
                return false
            }
        },
     
        validateEmail(email) {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validatePhone(mob_no) {
            let re = /^(\+\d{1,3}[- ]?)?\d{10}$/;
            return re.test(mob_no);
        }
      }
  }
  </script>
  <style scoped>
  .landing_page .button {
	background: #8279b7;
	border: 0;
	outline: 0;
	color: #fff;
	text-decoration: none !important;
	display: inline-block;
	padding: 18px 20px;
	min-width: 150px;
	text-align: center;
	border-radius: 6px;
}

.landing_page .button.button-white {
	background-color: #0F9D58;
	color: black;
}
  #banner-container{
   position:relative;
    width:fit-content;
    width: 100%;
  }
  #grad{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    mix-blend-mode: normal;
    border: 1px solid #000000;
    /* box-sizing: border-box; */
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  }
  #grad2{
    position: absolute;
    width:100%;
    height:100%;
    background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.78) 100%);
  }
  
  #banner-container img{
    width:100%;
    height:100%;
  }
  a{
    text-decoration: none;
  }
  clearImage{
    z-index: 99;
  }
  .title {
    background-color: #212529;
    font-size: 14px;
    color: #ffffff;
    padding: 0 5px;

}

.dropdownAngle {
    position: absolute;
    left: 100;
    top: 50;
    bottom: 50;
    right: 0;
    margin-right: 2%
}

.top_box {
    text-align: left;
    display: block;
    line-height: 0;
    height: 0;
    position: relative;
    left: 5px;
    font-size: 12px;
}

select {
    background-color: #202529;
    color: white;
    border: 2px solid #202529;
    font-size: 12px;
}

select:focus {
    background-color: #202529;
    color: white;
    border: 2px solid #202529;
    font-size: 12px;
}

.box {
    border: 2px solid #4f4f4f;
    text-align: justify;
    color: white;
    border-radius: 7px;
}

.box>input {
    border: 0;
    outline: 0;
    background-color: #212529;
    color: aliceblue;
    font-size: 12px;
}

::placeholder {
    /* Most modern browsers support this now. */
    color: #4f4f4f;
}


.line-separator {
    border: 1px solid #343434;
}

a {
    text-decoration: none;
    color: #B8F009;

}

a:focus {
    text-decoration: none;
    color: #B8F009;
}
.bringin-btn {
    color: #212121;
    background-color: #B8F009;
    outline: none;
    border: 2px solid #B8F009;
    border-radius: 10px;
}

.bringin-btn:hover {
    background-color: #212121;
    color: #B8F009;
    outline: none;
    border: 2px solid #B8F009;
    border-radius: 10px;
}

.bringin-btn:focus {
    background-color: #212121;
    color: #B8F009;
    outline: none;
    border: 2px solid #B8F009;
    border-radius: 10px;
}
  </style>

  